import React, { useState } from 'react';
import { Info, Mountain, Maximize2, X } from 'lucide-react';
import './Gallery.css';

import image1 from './images/1.jpg';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';
import image4 from './images/4.jpg';
import image5 from './images/5.jpg';
import image6 from './images/6.jpg';
import image7 from './images/7.jpg';
import image8 from './images/8.jpg';
import image9 from './images/9.jpg';
import image10 from './images/10.jpg';

const allAlbumImages = [
    {
        src: image1,
        title: "Exterior View",
        description: "Nestled in lush greenery, our homestay offers a serene escape from city life."
    },
    {
        src: image2,
        title: "Exterior View",
        description: "A beautiful exterior view showcasing the architectural design of our homestay."
    },
    {
        src: image3,
        title: "Exterior View",
        description: "The homestay's exterior at dusk, highlighting the warm and inviting ambiance."
    },
    {
        src: image4,
        title: "Bedroom",
        description: "A cozy bedroom with comfortable bedding and modern amenities for a restful stay."
    },
    {
        src: image5,
        title: "Bedroom",
        description: "Spacious bedroom with elegant decor and ample natural light."
    },
    {
        src: image6,
        title: "Bedroom",
        description: "Another view of the bedroom, featuring a work desk and seating area."
    },
    {
        src: image7,
        title: "Bedroom",
        description: "Luxurious bedroom with a king-sized bed and stylish furnishings."
    },
    {
        src: image8,
        title: "Bathroom",
        description: "Modern bathroom with sleek fixtures and a refreshing shower."
    },
    {
        src: image9,
        title: "Greenery Outside View",
        description: "Lush green surroundings providing a tranquil environment."
    },
    {
        src: image10,
        title: "Greenery Outside View",
        description: "Beautiful garden area with vibrant flowers and plants."
    }
];

const Gallery = () => {
    const [hoveredImage, setHoveredImage] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);
  
    const displayImages = showAll ? allAlbumImages : allAlbumImages.slice(0, 8);
  
    return (
        <section className="homestay-album-section">
            <div className="overview-container">
                <div className="overview-header">
                    <h2>Our Homestay</h2>
                    <div className="header-divider">
                        <div className="divider-content">
                            <span className="divider-line"></span>
                            <Mountain className="divider-icon" />
                            <span className="divider-line"></span>
                        </div>
                    </div>
                </div>

                <div className="album-grid">
                    {displayImages.map((image, index) => (
                        <div
                            key={index}
                            className="album-image-wrapper"
                            onMouseEnter={() => setHoveredImage(index)}
                            onMouseLeave={() => setHoveredImage(null)}
                        >
                            <img
                                src={image.src}
                                alt={image.title}
                                className="album-image"
                                onClick={() => setFullscreenImage(image)}
                            />
                            {hoveredImage === index && (
                                <div className="image-overlay">
                                    <div className="image-details">
                                        <div className="image-actions">
                                            <Info size={24} className="info-icon" />
                                            <Maximize2
                                                size={24}
                                                className="fullscreen-icon"
                                                onClick={() => setFullscreenImage(image)}
                                            />
                                        </div>
                                        <h3>{image.title}</h3>
                                        <p>{image.description}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {!showAll && (
                    <div className="show-more-container">
                        <button className="contact-btn" onClick={() => setShowAll(true)}>
                            Show More Images
                        </button>
                    </div>
                )}
            </div>
    
            {fullscreenImage && (
                <div className="fullscreen-overlay" onClick={() => setFullscreenImage(null)}>
                    <button className="close-fullscreen" onClick={() => setFullscreenImage(null)}>
                        <X size={32} />
                    </button>
                    <img
                        src={fullscreenImage.src}
                        alt={fullscreenImage.title}
                        className="fullscreen-image"
                    />
                    <div className="fullscreen-image-details">
                        <h3>{fullscreenImage.title}</h3>
                        <p>{fullscreenImage.description}</p>
                    </div>
                </div>
            )}
        </section>
    );
};
  
export default Gallery;