import React, { useEffect, useState, useRef } from 'react';
import { Star, Quote } from 'lucide-react';
import './Testimonials.css';

const testimonials = [
    {
        text: "I had a fantastic time at Dream Garden Homestay. The rooms were comfortable, with clean and spacious washrooms.",
        rating: 5,
        name: "Rohan D'Souza",
    },
    {
        text: "The evening campfire was amazing under the night sky",
        rating: 5,
        name: "Sathvik",
    },
    {
        text: "As a Bike rider, Parking area was spacious and able to park my bike side of my room",
        rating: 5,
        name: "Monish K S",
    },
    {
        text: "This homestay is filled with greenery and peaceful atmosphere.",
        rating: 4,
        name: "Sinchana",
    },
    {
        text: "Breakfast was delicious and the staff was incredibly friendly.",
        rating: 5,
        name: "Shruthik",
    },
    {
        text: "Perfect location for nature lovers with beautiful hiking trails nearby.",
        rating: 4,
        name: "Bhavish",
    },
];

const TestimonialCard = ({ text, rating, name }) => (
    <div className="testimonial-card">
        <div className="quote-icon">
            <Quote size={24} />
        </div>
        <p className="testimonial-text">{text}</p>
        <div className="testimonial-footer">
            <div className="rating">
                {[...Array(rating)].map((_, index) => (
                    <Star key={index} size={16} className="star-icon" fill="currentColor" />
                ))}
            </div>
            <span className="testimonial-name">{name}</span>
        </div>
    </div>
);

const Testimonials = () => {
    const [position, setPosition] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const trackRef = useRef(null);
    const animationFrameRef = useRef(null);
    const touchStartX = useRef(0);
    const isDragging = useRef(false);
    const cardWidth = 400; // Should match your CSS card width
    const totalWidth = testimonials.length * cardWidth;

    // Auto-scroll animation
    useEffect(() => {
        const animate = () => {
            if (!isPaused && !isDragging.current) {
                setPosition(prev => (prev + 0.5) % totalWidth);
            }
            animationFrameRef.current = requestAnimationFrame(animate);
        };
        
        animationFrameRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationFrameRef.current);
    }, [isPaused, totalWidth]);

    // Touch handlers
    const handleTouchStart = (e) => {
        isDragging.current = true;
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        if (!isDragging.current) return;
        const delta = touchStartX.current - e.touches[0].clientX;
        
        setPosition(prev => {
            let newPos = prev + delta;
            if (newPos < 0) newPos += totalWidth;
            return newPos % totalWidth;
        });
        
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        isDragging.current = false;
    };

    return (
        <section className="testimonials-section">
            <div className="testimonials-container">
                <h2 className="section-title">Guest Experiences</h2>
                <div 
                    className="testimonials-wrapper"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                >
                    <div
                        ref={trackRef}
                        className="testimonials-track"
                        style={{ transform: `translateX(-${position}px)` }}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        {[...testimonials, ...testimonials].map((testimonial, index) => (
                            <TestimonialCard key={index} {...testimonial} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;