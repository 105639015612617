import React from 'react';
import {
  MapPin,
  Phone,
  Mail,
  Facebook,
  Instagram,
  ChevronRight,
  Clock
} from 'lucide-react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-background">
        <div className="footer-shape shape-1"></div>
        <div className="footer-shape shape-2"></div>
      </div>
      <div className="footer-content">
        <div className="footer-sections">
          <div className="footer-section">
            <h3>Dream Garden Homestay</h3>
            <p className="about-text">
              Experience the perfect blend of comfort and nature at our serene homestay in Coorg. We offer a peaceful retreat surrounded by lush greenery and modern amenities.
            </p>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61572117944271" className="social-link">
                <Facebook size={20} />
              </a>
              <a href="https://www.instagram.com/dreamgardencoorg" className="social-link">
                <Instagram size={20} />
              </a>
              <a href="mailto:dreamgardencoorg@gmail.com" className="social-link">
                <Mail size={20} />
              </a>
            </div>
          </div>
          <div className="footer-section links">
            <h4>Quick Links</h4>
            <ul className="quick-links">
              <li>
                <ChevronRight size={16} />
                <a href="#overview">Overview</a>
              </li>
              <li>
                <ChevronRight size={16} />
                <a href="#gallery">Gallery</a>
              </li>
              <li>
                <ChevronRight size={16} />
                <a href="#accommodation">Accommodation</a>
              </li>
              <li>
                <ChevronRight size={16} />
                <a href="#location">Location</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact Info</h4>
            <div className="contact-info">
              <div className="contact-item">
                <MapPin size={20} />
                <p>Katakeri Village, Madikeri, Coorg, Karnataka - 571201</p>
              </div>
              <div className="contact-item">
                <Phone size={20} />
                <p>+91 8861384577</p>
              </div>
              <div className="contact-item">
                <Mail size={20} />
                <p>dreamgardencoorg@gmail.com</p>
              </div>
              <div className="contact-item">
                <Clock size={20} />
                <p>Check-in: 12 PM | Check-out: 11 AM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-divider"></div>
          <p className="copyright">
            © {currentYear} Dream Garden Homestay
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
