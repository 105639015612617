import React, { useState, useEffect } from 'react';
import { Menu, X, ArrowRight } from 'lucide-react';
import './Header.css';
import logo from '../../images/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navigationLinks = [
    'Overview', 
    'Gallery', 
    'Accommodation', 
    'Location'
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  const predefinedMessage = encodeURIComponent(
    "Hello! I am interested in booking a stay at Dream Garden Homestay. Please provide more details."
  );
  const whatsappLink = `https://wa.me/918861384577?text=${predefinedMessage}`;

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div 
          className="logo-container" 
          onClick={() => scrollToSection('overview')}
          style={{ cursor: 'pointer' }}
        >
          <img 
            src={logo} 
            alt="Dream Garden Logo" 
            className="logo"
          />
          <span className="brand-name">Dream Garden</span>
        </div>

        {/* Desktop Navigation */}
        <nav className="desktop-nav">
          {navigationLinks.map((item) => (
            <a 
              key={item}
              href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
              className={`nav-link ${activeLink === item ? 'active' : ''}`}
              onMouseEnter={() => setActiveLink(item)}
              onMouseLeave={() => setActiveLink('')}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(item.toLowerCase().replace(/\s+/g, '-'));
              }}
            >
              {item}
            </a>
          ))}
          
          <a 
            href={whatsappLink} 
            className="book-now-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Book Now</span>
            <ArrowRight size={20} className="btn-icon" />
          </a>
        </nav>

        {/* Mobile menu button */}
        <button 
          onClick={toggleMenu}
          className="mobile-menu-button"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Mobile Navigation */}
        <nav className={`mobile-nav ${isMenuOpen ? 'open' : ''}`}>
          {navigationLinks.map((item) => (
            <a
              key={item}
              href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
              className="mobile-nav-link"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(item.toLowerCase().replace(/\s+/g, '-'));
              }}
            >
              {item}
            </a>
          ))}
          <a 
            href={whatsappLink} 
            className="mobile-book-now-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now <ArrowRight size={20} className="btn-icon" />
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
