import './App.css';
import Accommodation from './components/Accommodation/Accommodation';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Location from './components/Location/Location';
import Overview from './components/Overview/Overview';
import Testimonials from './components/Testimonials/Testimonials';
import { MessageSquare } from 'lucide-react';

function App() {
  const predefinedMessage = encodeURIComponent(
    "Hello! I am interested in booking a stay at Dream Garden Homestay. Please provide more details."
  );
  const whatsappLink = `https://wa.me/918861384577?text=${predefinedMessage}`;

  return (
    <div className="App">
      <Header />
      <section id="overview">
        <Overview />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="accommodation">
        <Accommodation />
      </section>
      <Testimonials />
      <section id="location">
        <Location />
      </section>
      <Footer />
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <MessageSquare className="whatsapp-icon" />
      </a>
    </div>
  );
}

export default App;