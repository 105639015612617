import React, { useState, useEffect } from 'react';
import { FaWifi, FaParking, FaShower, FaCoffee, FaLeaf } from 'react-icons/fa';
import { GiCampfire, GiForestCamp, GiMountainCave } from 'react-icons/gi';
import campfire from '../../images/campfire.jpg';
import breakfast from '../../images/breakfast.jpg';
import nature from '../../images/nature.jpg';
import './Accommodation.css';

const Accommodation = () => {
    const [isHovered, setIsHovered] = useState(null);
    const [isVisible, setIsVisible] = useState({});

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsVisible(prev => ({
                        ...prev,
                        [entry.target.id]: entry.isIntersecting
                    }));
                });
            },
            { threshold: 0.1 }
        );

        document.querySelectorAll('.animate-on-scroll').forEach((el) => {
            observer.observe(el);
        });

        return () => observer.disconnect();
    }, []);

    const amenities = {
        comfort: [
            '24/7 Hot Water Availability',
            'Spacious, Well-Maintained Rooms',
            'Daily Housekeeping Service'
        ],
        connectivity: [
            'High-Speed WiFi Throughout',
            'Strong Network Coverage'
        ],
        outdoor: [
            'Evening Campfire Experience',
            'Lush Green Surroundings',
            'Peaceful Garden Seating'
        ],
        facilities: [
            'Spacious Secure Parking',
            'Fresh Homestyle Breakfast',
            'On-site Assistance'
        ]
    };

    const experiences = [
        {
            icon: <GiCampfire />,
            title: "Evening Campfire",
            description: "Enjoy our daily campfire under the stars, perfect for storytelling and stargazing",
            image: campfire
        },
        {
            icon: <FaCoffee />,
            title: "Fresh Breakfast",
            description: "Start your day with our delicious homestyle breakfast in the midst of nature",
            image: breakfast
        },
        {
            icon: <GiForestCamp />,
            title: "Nature Trails",
            description: "Explore the surrounding greenery through our carefully maintained nature trails",
            image: nature
        }
    ];

    return (
        <div className="accommodation">
            <div className="decorative-shape shape-1"></div>
            <div className="decorative-shape shape-2"></div>
            <div className="decorative-shape shape-3"></div>
            
            <div id="amenities" className="amenities-section animate-on-scroll">
                <div className="section-header">
                    <h2 className="section-title gradient-text">Premium Amenities</h2>
                    <div className="title-separator">
                        <span className="separator-line"></span>
                        <GiMountainCave className="separator-icon pulse" />
                        <span className="separator-line"></span>
                    </div>
                </div>
                
                <div className="amenities-grid">
                    {Object.entries(amenities).map(([key, items], index) => (
                        <div
                            key={key}
                            className={`amenity-card ${isHovered === key ? 'hovered' : ''}`}
                            onMouseEnter={() => setIsHovered(key)}
                            onMouseLeave={() => setIsHovered(null)}
                            style={{ 
                                animationDelay: `${index * 0.2}s`,
                                transform: isVisible['amenities'] ? 'translateY(0)' : 'translateY(50px)',
                                opacity: isVisible['amenities'] ? 1 : 0
                            }}
                        >
                            <div className="amenity-header">
                                <div className="icon-wrapper">
                                    {key === 'connectivity' && <FaWifi className="animated-icon" />}
                                    {key === 'comfort' && <FaShower className="animated-icon" />}
                                    {key === 'outdoor' && <FaLeaf className="animated-icon" />}
                                    {key === 'facilities' && <FaParking className="animated-icon" />}
                                </div>
                                <h3 className="gradient-text">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                            </div>
                            <ul className="amenity-list">
                                {items.map((item, idx) => (
                                    <li 
                                        key={idx} 
                                        className="amenity-item"
                                        style={{ animationDelay: `${idx * 0.1 + index * 0.2}s` }}
                                    >
                                        <span className="bullet"></span>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            <div id="experiences" className="experience-section animate-on-scroll">
                <div className="experience-content">
                    <h2 className="section-title gradient-text">Signature Experiences</h2>
                    <div className="experience-grid">
                        {experiences.map((experience, index) => (
                            <div 
                                key={index}
                                className="experience-card"
                                style={{ 
                                    animationDelay: `${index * 0.2}s`,
                                    transform: isVisible['experiences'] ? 'translateY(0)' : 'translateY(50px)',
                                    opacity: isVisible['experiences'] ? 1 : 0
                                }}
                            >
                                <div className="experience-image-container">
                                    <img 
                                        src={experience.image} 
                                        alt={experience.title} 
                                        className="experience-image" 
                                    />
                                </div>
                                <div className="experience-icon">
                                    {experience.icon}
                                </div>
                                <h3 className="gradient-text">{experience.title}</h3>
                                <p>{experience.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accommodation;