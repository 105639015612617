import React, { useState, useEffect, useRef } from "react";
import { Mountain, MessageCircle, Play, Pause, Volume2, VolumeX } from "lucide-react";
import "./Overview.css";
// Import your images and video
import dreamgarden1 from "./images/1.jpg";
import dreamgarden2 from "./images/2.jpg";
import dreamgarden3 from "./images/3.jpg";
import homestayvideo from "../../videos/homestay.MOV";

const Overview = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const images = [dreamgarden1, dreamgarden2, dreamgarden3];

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(imageInterval);
  }, [images.length]);

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  const handleWhatsAppBooking = () => {
    window.open("https://wa.me/918861384577", "_blank");
  };

  return (
    <div className="overview">
      <div className="background-shape shape-left"></div>
      <div className="background-shape shape-right"></div>
      <div className="overview-container">
        <div className="overview-header">
          <h2>Welcome to Dream Garden Homestay</h2>
          <div className="header-divider">
            <div className="divider-content">
              <span className="divider-line"></span>
              <Mountain className="divider-icon" />
              <span className="divider-line"></span>
            </div>
          </div>
        </div>

        <div className="overview-content">
          <div className="left-column">
            <div className="content-card text-content">
              <div className="card-inner">
                <p className="description-text">
                  Our homestay is in the beautiful village of Katakeri, Madikeri, and is the perfect place to relax in nature.
                  Surrounded by lush greenery and thriving areca nut plantations, you'll be embraced by the tranquility
                  of Coorg's stunning landscapes.
                </p>
                <div className="content-divider">
                  <div className="divider-content">
                    <span className="divider-line"></span>
                    <Mountain className="divider-icon" />
                    <span className="divider-line"></span>
                  </div>
                </div>
                <p className="description-text">
                  Our rooms are designed for comfort and convenience, featuring spacious and clean attached washrooms
                  and toilets. Begin your mornings with a delicious breakfast while soaking in the lush surroundings.
                </p>
                <p className="description-text">
                  As night falls, gather around a cozy campfire under the starlit sky and immerse yourself in the
                  serene beauty of Coorg.
                </p>
              </div>
            </div>
            <div className="video-section">
              <div className="video-container">
                <video
                  ref={videoRef}
                  id="homestay-video"
                  src={homestayvideo}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="homestay-video"
                />
                <div className="video-controls">
                  <button 
                    className="video-control-btn" 
                    onClick={toggleVideoPlayback} 
                    aria-label="Play or Pause Video"
                  >
                    {isVideoPlaying ? <Pause size={24} /> : <Play size={24} />}
                  </button>
                  <button 
                    className="video-control-btn" 
                    onClick={toggleMute} 
                    aria-label="Toggle Sound"
                  >
                    {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className="content-card image-carousel">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Dream Garden ${index + 1}`}
                  className={`carousel-image ${index === currentImageIndex ? "active" : ""}`}
                />
              ))}
            </div>
            <div className="contact-card">
              <h3>Connect With Us</h3>
              <p>Have questions or ready to book your perfect homestay? Reach out to us directly on WhatsApp!</p>
              <div className="contact-actions">
                <button 
                  className="contact-btn" 
                  onClick={handleWhatsAppBooking} 
                  aria-label="Contact via WhatsApp"
                >
                  <MessageCircle size={20} />
                  Chat on WhatsApp
                </button>
              </div>
              <div className="contact-details">
                <p>📞 +91 8861384577</p>
                <p>📍 Katakeri, Madikeri, Coorg</p>
                <p>✉️ dreamgardencoorg@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="closing-text">
        <strong>Come, escape the ordinary, and create unforgettable memories with us.</strong>
      </p>
    </div>
  );
};

export default Overview;