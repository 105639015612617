import React from 'react';
import { FaMapMarkerAlt, FaCompass } from 'react-icons/fa';
import './Location.css'

const Location = () => {
  return (
    <div id="location" className="location-section animate-on-scroll">
      <div className="section-header">
        <h2 className="section-title gradient-text">Our Location</h2>
        <div className="title-separator">
          <span className="separator-line"></span>
          <FaMapMarkerAlt className="separator-icon pulse" />
          <span className="separator-line"></span>
        </div>
      </div>
      <div className="location-container">
        <div className="map-container">
          <iframe
            title="Property Location"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3896.404456904635!2d75.69945287506725!3d12.422749987844462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDI1JzIxLjkiTiA3NcKwNDInMDcuMyJF!5e0!3m2!1sen!2sin!4v1737577509139!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="location-details">
          <div className="address-card">
            <div className="location-icon">
              <FaMapMarkerAlt />
            </div>
            <h3>Address</h3>
            <p>Katakeri Village, Madikeri</p>
            <p>Coorg, Karnataka - 571201</p>
          </div>
          <div className="directions-container">
            <h3>Nearby Attractions</h3>
            <div className="transport-option">
              <div className="transport-icon">
                <FaCompass />
              </div>
              <div className="transport-details">
                <h4>Explore Coorg</h4>
                <p>Raja's Seat - 7 km</p>
                <p>Madikeri Fort - 6 km</p>
                <p>Abbey Falls - 14 km</p>
                <p>Omkareshwara Temple - 7 km</p>
                <p>Cauvery Nisargadhama - 35 km</p>
                <p>Dubare Elephant Camp - 30 km</p>
                <p>Golden Temple - 40 km</p>
                <p>Talacauvery - 50 km</p>
                <p>Mandalpatti View Point - 20 km</p>
                <p>Iruppu Falls - 80 km</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
